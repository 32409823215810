import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as MobXProvider } from 'mobx-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'libs/payer'
import 'libs/common/analytics'

import { I18nProvider } from '@elo-kit/components'

import { initSentry } from 'libs/common/sentry'
import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'

import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'

import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { profile } from 'utils/profileHelper.utils'
import { handleInitCabinetFetching } from 'utils/requests.utils'

import { MembershipTheme } from './components/common/MembershipTheme'
import { MetaTags } from './components/common/MetaTags'
import { PageWrapper } from './components/common/page-wrapper'
import { CookieBot } from './components/common/CookieBot'

import { ModalsRenderer } from './components/modals'

import { usePayerStore } from './hooks/use-payer-stores'

import { Routes } from './routes'

profile.setProfileType(ACTIVE_PROFILES.payer)

initSentry()

import './index.scss'

export const App = () => {
  const stores = usePayerStore()
  const { userStore, payerStore, countriesStore } = stores

  useEffect(() => {
    handleInitCabinetFetching(userStore, payerStore, false)
    countriesStore.fetchList()
  }, [])

  return (
    <I18nProvider>
      <MobXProvider {...stores}>
        <Router>
          <MetaTags />
          <PageWrapper>
            <Routes />
            <ModalsRenderer />
            <ApiBranch />
            <AdminMode userEmail={userStore?.item?.email} />
          </PageWrapper>
          <MembershipTheme />
        </Router>
        {/*<UserFlow />*/}
        <CookieBot />
        <ToastContainer />
      </MobXProvider>
    </I18nProvider>
  )
}

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
  // </React.StrictMode>
)
